// MAIN REGULAR ITEM FOR BLOCKS:

.item-block {
  word-wrap: break-word;
  background-color: rgba($white,0.9);

  @include mobile {
    border-top: 1px solid $lightgrey7;
    border-bottom: 1px solid $lightgrey7;
  }

  @include md-up {
    border-radius: 3px;
    border: 1px solid $lightgrey7 !important;
  }

  &.smilingdocs-navbar {
    font-family: $font-alter;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    border: none !important;
    overflow: hidden;
    .nav-link {
      color: white;
      background: $main;
    }
    .nav-link.active {
      background: white;
      color: $main;
    }
    a {
      display: block;
      outline: none;
    }
  }

  &.dentist-info {
    .ava-wrap {
      width: 40%;
      text-align: center;
      padding-right: 15px;
      &.is-full-width {
        width: 100%;
        padding-right: 0;
        + .right-part {
          width: 100%;
        }
      }
      &.is-low-width {
        width: 30%;
        + .right-part {
          width: 70%;
        }
      }
      img {
        max-width: 100%;
        border-radius: 0.25rem;
      }
    }
    .sm-spaced {
      font-family: 'Slabo 27px', serif;
      letter-spacing: 1px;
      font-size: 14px;
    }

    .right-part {
      width: 60%;
    }
    .right-part-mod {
      width: 50%;
    }
    .left-part-mod {
      width: 50%;
    }

    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
      .ava-wrap {
        margin-bottom: 1rem;
        width: 100%;
        padding-right: 0px;
        &.is-low-width {
          width: 100%;
          + .right-part {
            width: 100%;
          }
        }
      }
      .right-part {
        width: 100%;
      }
      .right-part-mod {
        width: 100%;
      }
      .flexbox {
        flex-wrap: wrap;
      }
    }
  }

  &.no-border {
    border: none!important;
  }

  &.cities-sidebar {
    margin-top: 0px;
    max-height: calc(100vh - 330px);
    overflow-y: auto;
    @include custom-scrolltumb;
  }

  &.neighborhoods-sidebar {
    margin-top: 134px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    @include custom-scrolltumb;
  }

  .zip-search-form {
    width: 195px;
    @include mobile {
      width: 100%;
      margin: 0 !important;
    }
  }


  .dashed {
    list-style-type: none;
  }

  .dashed li:before {
    content: '\2014';
    position: absolute;
    margin-left: -20px;
  }

 #search-res {
    position: absolute;
    top: 100%;
    list-style-type: none;
    background: #fff;
    width: calc(100% - 30px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  }

  #search-res li > a {
      display: block;
      padding: 3px 20px;
      color: #333;
  }

  #search-res li > a:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
  }
  .list-divider {
    padding-top: 10px; 
    padding-bottom: 10px; 
  }
  .list-divider-line {
    border-bottom: 1px solid rgba(128,128,128,0.32);
  }

  #emailNotificationStatus {
    font-family:"Helvetica Neue,Helvetica,Arial",sans-serif;
    text-decoration: underline;
    font-size:16px;
  }
}

.facebookPost {
  .title {
    position: relative;
    .dropdown {
      position: absolute;
      right: 0;
      top: 0;
      .dropdown-toggle {
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
  .review-photo {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    max-width: none;
    border-radius: 0.25rem;
  }
  @include mobile {
    .title {
      margin-bottom: 15px;
    }
    .post-caption {
      align-items: flex-start;
    }
    .ava-part {
      margin-top: 4px;
    }
  }
}

.margin-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padding-horizontal-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.summernote-textarea .note-editable {
  text-align: left;
}
