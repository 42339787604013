.select-drop-down {
  background-color: #e6e6e6;
}

.location-dropdown {
  .btn {
    text-align: left;
    padding-right: 20px;
    &:hover, &:focus {
      color: #333 !important;
    }
  }

  &.open .btn {
    color: #333 !important;
  }

  .caret {
    float: right;
    position: absolute;
    right: 10px;
    right: 6px;
    top: 50%;
    margin-top: -2px;
  }
}

.select-practice {
  li {
    list-style: none;
    border-radius: 4px;
    a {
      color: #337ab7;
      &:hover {
        color: #23527c;
      }
    }
    .sitemap-li {
      display: block;
      line-height: 1.1;
    }
  }
}

/* DENTIST PAGE - REVIEW STAR */
.glyphicon-star {
  font-size: 18px;
  color: #ff6600;
  &.half {
    position: relative;
    &:before {
      position: relative;
      z-index: 9;
      width: 47%;
      display: block;
      overflow: hidden;
    }
    &:after {
      content: '\e006';
      position: absolute;
      z-index: 8;
      color: #bdc3c7;
      top: 0;
      left: 0;
    }
  }
  &.empty {
    position: relative;
    &:after {
      content: '\e006';
      position: absolute;
      z-index: 8;
      color: #bdc3c7;
      top: 0;
      left: 0;
    }
  }
}

.glyphicon-star-4 {
  color: #ff6600;
}

.glyphicon-star-3 {
  color: blue;
}

.glyphicon-star-2 {
  color: gray;
}

.glyphicon-star-1 {
  color: red;
}

.glyphicon-usd-4 {
  color: red;
}

.glyphicon-usd-3 {
  color: gray;
}

.glyphicon-usd-2 {
  color: blue;
}

.glyphicon-usd-1 {
  color: #30b724
}

/* DENTIST PAGE - TO TOP BUTTON */
.to-top-button {
  height: auto !important;
  position: absolute;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  top: 0px;
  right: 0px;
  font-size: 18px;
  background: $white;
  border-radius: 3px;
  border: 1px solid $lightgrey7;

  > i {
    position: relative;
    display: flex;
    top: 1px;
  }
}

/* DENTIST PAGE SMILE ICONS */
.smile-rating-module {
  .smile {
    font-size: 20px;
    color: rgb(189, 189, 189);
    outline: none;
    &::before {
      margin-right: 0 !important;
      margin-left: 0 !important;
      width: 18px;
      height: 18px;
    }
    &.on {
      &::before {
        margin-right: 0!important;
        margin-left: 0!important;
        width: 18px;
        height: 18px;
        color: #4A0D80;
        border-radius: 50%;
        background-color: gold;
      }
    }
  }
  .rating-caption {
    position: absolute;
    top: 22px;
    left: 0;
    margin: 0;
  }
  .glyphicon-star.on {
      color: #ff6600;
      &::before {
          background-color: transparent;
          color: #ff6600;
      }
  }
    /* FOR COMPARE RATINGS ONLY */
    &.is-compare-smiley-wrap {
        .positive-title, .negative-title, .positive-title2, .negative-title2 {
          display: none;
          margin-left: 5px !important;
          font-size: 15px;
          float: left;
        }
        .smileys-wrap {
            

            &.positive ~ .positive-title {
                display: initial !important;
            }                
            &.negative ~ .negative-title {
                display: initial !important;
            }            
            &.positive2 ~ .positive-title2 {
                display: initial !important;
            }                
            &.negative2 ~ .negative-title2 {
                display: initial !important;
            }
            .smile {
                vertical-align: top;
                &:first-child {
                  margin-right: 30px;
                }
                &.icon-thumbs-up.on {
                  color: $success;
                  &::before {
                    color: inherit;
                    background-color: transparent;
                  }
                }
                &.icon-thumbs-down.on {
                  color: $danger-cl;
                  &::before {
                    color: inherit;
                    background-color: transparent;
                  }
                }
            }

        }
    }
}

/* DENTIST PAGE - ABOUT DENTIST - TITLE WITH ARROW */

.arrow-title {
  -webkit-user-select: none;
  &::before {
    content: "\e259";
    float: right;
    margin-left: 24px;
    font-family: 'Glyphicons Halflings';
  }
}

/* END */

.sitemap-li {
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.sitemap-li-background {
  background: #dfe8fa;
}

.sitemap-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.sitemap-anchors {
  margin-right: 0.5rem !important;
  color: #828ed2;
}

.scroll-to-top {
  display: inline-block;
  position: fixed;
  top: 94%;
  right: 40px;
}

.small-avatar {
  height: 100px;
  border-radius:5px;
}
.button-margin-bottom {
  margin-bottom: 10px;
}
.rating {
  vertical-align: middle;
  white-space:nowrap;
}
.reviewbox {
  min-height:150px;
  @include mobile {
        min-height:0px;
     }
}

.video-container {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow-y: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height: calc(100% - 30px);

  + * {
    position: absolute;
    top: 100%;
    margin-top: -25px;
    left: 50%;
    margin-left: -100px;
  }
}

.description-oogle {
  font-family: Bookmania,Georgia,Times,"Times New Roman",serif;
  font-weight: 400;
  font-size: 20px!important;
  line-height: 1.2727272727em;
}

.dangerDentistTab {
  position: fixed;
  top: 51px;
  left: 50%;
  z-index: 1001;
  transform: translateX(-50%);
  max-width:100%;
}

.easy-loader {
    border: 8px solid transparent;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    border-top: 8px solid $main;
    border-right: 8px solid $main;
    border-radius: 50%;
    border-bottom: 8px solid $main;
    width: 120px;
    height: 120px;
    display: block;
    margin: auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.nearby-title {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.icon-angle-double-up:before {
    line-height:0.25em;
}

.dentist-registration .file-field > .material-btn {
  background-color: #9e9e9e !important;
}

.rangeslider__fill {
  background-color: #828ed2 !important;
}

.rankerror {
  background-color: #828ed2 !important;
  padding: 1em;
  color: $white;
  border-radius:5px;
}

.map-popup-module {
  width: auto;
  padding: 0;
  color: #333333;
  background: white;
  margin-bottom: 0 !important;

  .left-part {
    float: left;
    width: calc(100% - 100px);
    h5 {
      color: #0073BB;
      font-size: 1rem;
    }
    img {
      height: 14px;
      margin-right: 3px;
    }
  }

  .right-part {
    float: left;
    width: 90px;
    text-align: center;
    img {
      max-width: 90px;
      max-height: 70px;
    }
  }
}

.featured-city-image {
  border: 1px solid #e7e7e7;
  .image-caption {
    position: absolute;
    width:100%;
    bottom: 15px;
    color: white;
    text-shadow: 1px 1px #333333;
    text-decoration:none;
    font-size: 30px;
  }
}

.reviewPreloader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid $main; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin: 1.50rem auto 0.50rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.ui-timepicker-wrapper {
  z-index: 1001 !important;
}

.photo-paginaor-arrow {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 40px;
  &.arrow-left {
    left: -15px;
  }

  &.arrow-right {
    right: -15px;
  }

  span {
    color: $main;
    font-size: 40px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    width: 30px;
    margin-top: -12px;
    display: flex;
    align-items: center;

    span {
      font-size: 30px;
    }
  }
}

.address-form{
  display: none;
}

input[type=text]:not(.browser-default):disabled.material-input {
  color: rgba(0,0,0,0.6);
}

.agreeDisModule {
  display: inline-block;
  > .btn {
    padding: 0px 3px;
    margin-top: -2px;
  }
}

#jsCouponsArticlesModal font[size] {
  font-size: 15px !important;
}

@media (min-width: 992px) {
  .left-sidebar-main-page + div {
    min-height: 280px;
  }
}

.tel-limited {
  max-width: 78px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  cursor: pointer;
  color: $main;
}