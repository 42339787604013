.temp-review-page {
	.checkbox-items {
	    position: absolute;
	    left: -32px;
	    top: 3px;
	}
	.smile-rating-module {
	    .text-info {
			float: left;
			width: 200px;
	    }
	    .rating-caption {
            position: static;
            text-align: left;
            float: left;
            margin-left: 15px;
        }
        .smileys-wrap {
			float: left;
        	margin-left: 15px;
        }
	}

	._gen-page-review-part {
		float: right;
	}

	@include mobile {
		.smile-rating-module {
			.text-info {
				width: 40%;
				text-align: right;
				min-height: 50px;
			}
			.rating-caption {
				min-width: 50%;
			}
		}

		.px-xs-0 {
			padding-left: 0;
			padding-right: 0;
		}

		.checkbox-items {
			position: static;
			padding: 0px 15px;
		}
		.jsOptionalCheckBox + div {
			text-align: center;
		}
	}

	@media (max-width: 360px) {
		.smile-rating-module {
			.text-info {
				width: 36%;
			}
		}
	}
}