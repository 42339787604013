$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin mobile {
  //@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
  @media (max-width: #{$md - 1px}) {
    @content;
  }
}
@mixin tablet {
  //@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
  @media (max-width: #{$xl - 1px}) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: #{$sm - 1px}) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: #{$sm}) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: #{$md}) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: #{$lg - 1px}) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: #{$lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}