// HERE WE WRITE HELPER CLASSES FOR POSITIONING, MARGINS:

.embed-iframe {
  border: 1px solid black;
}

.bold {
  font-weight: bold;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.rounded-50 {
    border-radius: 0.50rem !important;
}

.user-select-none {
  -webkit-user-select: none;
}

.readable-text {
  font-size: 1.06rem;
  font-family: Arial;
  &.is-times {
    font-family: Times;
    font-size: 1.33rem;
  }
}

.edited-text img{
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  position: static;
}

.user-link {
  opacity: .9;
  cursor: pointer;
  color: #000;
  &:hover {
    color: #000;
  }
}

.jodit-editor {
  text-align: left;
}

.pointer-event-none {
  pointer-events: none;
}

.changed-grey {
    background-color: rgba(227, 227, 229, 0.53) !important;
    margin-top: 8px;
    color: #7d7d7d;
}

.toast-top-center {
    margin-top: 60px;
}

#toast-container>div {
    opacity: 0.95 !important;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2) !important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2) !important;
}

#toast-container > .toast-error {
    background-color: #C64444;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.full-width {
  width: 100% !important;
}

// SKELETON LAYOUT //

.global-section {
  min-height: calc(100% - 50px);
}

.fixed-nav {
  @media (min-width: 1200px) {
    position: absolute;
    width: 290px;
    max-height: calc(100% - 100px);
    padding-right: 0px;
    @include custom-scrolltumb;
  }
  &.left-sidebar-main-page {
    //overflow: hidden;
  }
}

.dentist-review-page .fixed-nav {
  overflow: auto;
}

.height-truncated {
  overflow: auto;
  max-height: 50rem;
  @include custom-scrolltumb;
}

.avatar-bigger {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
}

.rounded {
  border-radius: 0.25rem;
}

.frame {
    border: 1px solid black;
}

label.appointment-error {
    color: #c72525 !important;
    margin: 5px 0px 0px 0px !important;
    position: static !important;
}

.img-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

// FLEXBOX UTILITIES:
.flexbox {
  display: flex;
  &.vertical {
    flex-direction: column;
  }
  &.items-center {
    align-items: center;
  }
  &.items-stretch {
    align-items: stretch;
  }
  &.items-end {
    align-items: flex-end;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
}

.grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

.no-grow {
  flex-grow: 0;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: flex-start;
}
#contact-message-error, #contact-subject-error {
  position: relative;
  display: block;
  top: 0px;
  font-size: 0.8rem;
  &.active {
    transform: none !important;
  }
}
.carousel-inner.custom-height {
  max-height: 190px;
  height: 190px;
  .item img {
    max-width: 100%;
    border-radius: 0.25rem;
    max-height: 190px;
    margin: auto;
  }
}

.inline-icon-link {
  display: inline !important;
  color: inherit;
}

a.inline-icon-link:hover, a.inline-icon-link:visited, a.inline-icon-link:active {
  color: inherit !important;
}

.subject-wrapper {
  display: none;
}

.coupon-image {
    border-radius: 0.25rem;
}

#optionsSubject3:checked ~ .subject-wrapper {
  display: block;
}

// MARGINS PADDINGS STYLES:

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 1 through 100 {
  .min-width-#{1 * $i}-rem {
    min-width: 1rem * $i;
  }
  .max-width-#{1 * $i}-rem {
    max-width: 1rem * $i;
  }
  .min-height-#{1 * $i}-rem {
    min-height: 1rem * $i;
  }
  .max-height-#{1 * $i}-rem {
    max-height: 1rem * $i;
  }
  .width-#{1 * $i}-rem {
    width: 1rem * $i;
  }
}

@for $i from 0 through 5 {
  .m-#{$i} {
    margin: 0rem + $i !important;
  }
  .mx-#{$i} {
    margin-left: 0rem + $i !important;
    margin-right: 0rem + $i !important;
  }
  .my-#{$i} {
    margin-top: 0rem + $i !important;
    margin-bottom: 0rem + $i !important;
  }
  .mt-#{$i} {
    margin-top: 0rem + $i !important;
  }
  .mt-#{$i} {
    margin-top: 0rem + $i !important;
  }
  .mr-#{$i} {
    margin-right: 0rem + $i !important;
  }
  .mb-#{$i} {
    margin-bottom: 0rem + $i !important;
  }
  .ml-#{$i} {
    margin-left: 0rem + $i !important;
  }

  .p-#{$i} {
    padding: 0rem + $i !important;
  }
  .px-#{$i} {
    padding-left: 0rem + $i !important;
    padding-right: 0rem + $i !important;
  }
  .py-#{$i} {
    padding-top: 0rem + $i !important;
    padding-bottom: 0rem + $i !important;
  }
  .pt-#{$i} {
    padding-top: 0rem + $i !important;
  }
  .pt-#{$i} {
    padding-top: 0rem + $i !important;
  }
  .pr-#{$i} {
    padding-right: 0rem + $i !important;
  }
  .pb-#{$i} {
    padding-bottom: 0rem + $i !important;
  }
  .pl-#{$i} {
    padding-left: 0rem + $i !important;
  }
}

@for $y from 0 through 2 {
  @for $i from 1 through 99 {
    .m-#{$y}-#{$i} {
      margin: (0rem + $y) + ($i * 0.01)  !important;
    }
    .mx-#{$y}-#{$i} {
      margin-left: (0rem + $y) + ($i * 0.01) !important;
      margin-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .my-#{$y}-#{$i} {
      margin-top: (0rem + $y) + ($i * 0.01) !important;
      margin-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .mt-#{$y}-#{$i} {
      margin-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .mt-#{$y}-#{$i} {
      margin-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .mr-#{$y}-#{$i} {
      margin-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .mb-#{$y}-#{$i} {
      margin-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .ml-#{$y}-#{$i} {
      margin-left: (0rem + $y) + ($i * 0.01) !important;
    }

    .p-#{$y}-#{$i} {
      padding: (0rem + $y) + ($i * 0.01) !important;
    }
    .px-#{$y}-#{$i} {
      padding-left: (0rem + $y) + ($i * 0.01) !important;
      padding-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .py-#{$y}-#{$i} {
      padding-top: (0rem + $y) + ($i * 0.01) !important;
      padding-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .pt-#{$y}-#{$i} {
      padding-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .pt-#{$y}-#{$i} {
      padding-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .pr-#{$y}-#{$i} {
      padding-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .pb-#{$y}-#{$i} {
      padding-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .pl-#{$y}-#{$i} {
      padding-left: (0rem + $y) + ($i * 0.01) !important;
    }
  }
}

/* ANCHORS */

.anchor_fixed {
  position: relative;
  visibility: hidden;
  display: block;
  z-index: -1;
  top: -70px;
}

.full-width-img-xs {
  max-width: none;
  width: calc(100% + 30px);
  margin-left: -15px;
}

a.no-style-link {
  color: initial !important;
}

a.no-style-link:hover {
  font-weight: initial;
}

.overlay-area {
  overflow-x: scroll;
  overflow-wrap: break-word;
}

.fadeout {
    position: absolute;
    bottom: 4em;
    height: 4em;
    background: -webkit-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.file-path::placeholder {
  color: #4A4646 !important;
}

.title-wrap {
  word-break: break-word;
}