html {
	font-size: 15px;
	background-color: $background;
}

iframe {
  border: 0px solid black;
}

body {
	min-height: 50px;
	padding-top: 70px;
	background: transparent;
	color: $main-font;
	font-size: 15px;

	@include mobile {
		padding-top: 0px;
	 }
}

html, body {
	margin: 0;
	width: 100%;
	height: 100%;
	font-family: $font-regular;
	font-size: 15px;
    @include mobile {
        font-size: 16px;
     }
}

button {
	outline: none;
	border: none;
	user-select: none;
}

ul {
	margin: 0;
	padding: 0;
}

.activeT {
	background: #26a69a;
    color: white !important;
    border-radius: 0.25rem;

}
a {
    color: $link-cl;
}
a:hover {
    color: $link-cl;
    font-weight: bold;
}
a, a:visited,a:hover,a:focus,a:active {
  text-decoration: none;
}

label {
	display: block;
  	text-align: left;
}

strong {
  	font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.1;
	font-weight: bold;
}

h1,h5 {
	font-size:1.25rem;
	line-height: 1.25;
}

h6 {
	font-size: 1rem;
}

img {
	max-width: 100%;
    max-height: 1000px;
}

.red {
	color: red;
}
.font-size-small {
	font-size: small;
}

.terms-block {
	height: 300px;
	overflow-y: auto;
}

.throbber-wrapper {
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    background-color: rgba(153, 153, 153, 0.4);
}

.loading {
    top: calc(50% - 33px);
    left: calc(50% - 33px);
    position: absolute;
}

.elastic-form {
    margin-left: -16px;
    margin-top: -20px!important;
}

#tech_support {
	text-transform: uppercase;
}

.droogle-logo {
      height: 32px;
    }

.px-xs-0 {
    @include mobile {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
}

.px-xs-1 {
    @include mobile {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

/*.px-xs-0-50 {
    @include mobile {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
}*/

.mt-xs-1-50 {
    @include mobile {
        margin-top: 1.5rem !important;
    }
}

.mx-xs--15 {
    @include mobile {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.mx-xs-0 {
    @include mobile {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}
.pl-xs-0 {
    @include mobile {
        padding-left: 0rem !important;
    }
}

.pr-xs-0 {
    @include mobile {
        padding-right: 0rem !important;
    }
}

.pl-xs-0-50 {
    @include mobile {
        padding-left: 0.50rem !important;
    }
}

.pr-xs-0-50 {
    @include mobile {
        padding-right: 0.50rem !important;
    }
}

.mb-xs-1-50 {
    @include mobile {
        margin-bottom: 1.5rem!important;
    }
}

.dentist-widget {
	height: 322px;
    resize: none;
	text-align: left;
}

.relative {
    position:relative
    }

.image-overlay-text {
    position: absolute;
    top: 14px;
    left: 10px;
    color:white;
    border-radius:5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    cursor: pointer; cursor: hand;
}

.text-larger {
    font-size: 1.1rem;
    @include mobile {
		font-size: 1.0rem!important;
	 }
}

.text-somewhat-larger {
    font-size: 1.05rem;
}

.text-small {
    font-size: 0.9rem!important;
}

.text-smaller {
    font-size: 0.95rem!important;
}

.text-tiny {
    font-size: 0.7rem!important;
}

.text-mobile-normal {
@include mobile {
		font-size: 1.0rem!important;
	 }
}
.no-border {
    border:0px!important;
}

.microdata {
    display: none;
}


.faces-gallery-image {
    max-width: 100px!important;
    max-height: 100px!important;
}

.form-border {
border: 1px solid #26a69a!important;
        border-radius: 5px;
}


hr.hrstyle {
    width:20%;
	border-color:rgb(171, 167, 217)!important;
}


.plain {
    font-weight:400!important;
}

a.plain-white:active {
    color: white!important;
    text-decoration:normal;
}

.main-background {
    background-color: $main!important;
}

A.moderator {color:$main;font-weight:400!important;}
A.moderator:link {color: $main;}
A.moderator:visited {color:$facebook-blue;}
A.moderator:hover {color: $main;}
A.moderator:active {color: $main;}

.inline-block {
    display:inline-block !important;
}

.first-dentist-bg {
    background-color:#fffdf8!important;
}

.second-dentist-bg {
    background-color:#f7f7f7!important;
}

.pr-4 {
        padding-right: 4rem;
}

.pr-5 {
        padding-right: 5rem;
}

.float-none {
    float:none!important;
}

.size-normal {
    font-size:1rem!important;
}

.margin-negative {
    margin:-1px;
}

.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 14em;
}

.main-background {
    background-color: #26a69a;
    color: white;
}

.position-static {
    position:static;
}

A.underline:hover {
    text-decoration:underline;
}