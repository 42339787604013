#cloud {
  width: 425px;
  min-height: 150px;
  height: auto;
  z-index: 10;

  background: #f2f9fe;
  background: linear-gradient(top, #f2f9fe 5%, #d6f0fd 100%);
  background: -webkit-linear-gradient(top, #f2f9fe 5%, #d6f0fd 100%);
  background: -moz-linear-gradient(top, #f2f9fe 5%, #d6f0fd 100%);
  background: -ms-linear-gradient(top, #f2f9fe 5%, #d6f0fd 100%);
  background: -o-linear-gradient(top, #f2f9fe 5%, #d6f0fd 100%);

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;

  position: relative;
  margin: 100px auto 20px;

  &:after,&:before {
    content: '';
    position: absolute;
    background: #f2f9fe;
    z-index: -1
  }

  &:after {
    width: 120px; height: 120px;
    top: -50px; left: 50px;

    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
  }

  &:before {
    width: 216px; height: 216px;
    top: -90px; right: 50px;

    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
  }

  @include sm-down {
    width: 260px;
    padding: 15px 0 !important;
    &:after {
      width: 83px; height: 83px;
    }
    &:before {
      width: 151px; height: 151px;
    }
    .shadow {
      width: 290px;
      margin-left: -15px;
    }
  }
}
