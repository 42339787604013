.modal {
  max-height: 98%;
  overflow-y: auto;
  .modal-header {
    background: $main;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  .close {
    font-size: 1.8rem;
    margin-top: -5px;
    position: relative;
  }

  .modal-footer {
    text-align: center;
  }
}

.modal-ios {
    position: absolute;
}

.welcomeModal {
  .modal-footer {
    background: #26a69a;
    background-color: #26a69a;
    background-image: url('../img/banner-back.png');
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    background-size: contain;
    background-position: center center;
    padding-bottom: 50px;
    padding-top: 50px;

    img {
      max-width: 250px;
    }

    h5 {
      color: white;
      line-height: 1.5;
    }
    .is-reverse {
      color: #2bbbad !important;
      background-color: white !important;
    }
  }
}