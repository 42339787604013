.dentist-location {
  .dropdown-menu li a {
    padding: 3px 0.75rem;
    color: $main;
    transition: all 0.3s ease;
    &:hover {
      background: $main;
      color: white;
    }
  }
  .location-dropdown > select {
    padding: 50px 150px !important;
    font-size: 15px !important;
    background: white !important;
    font-family: $font-regular;
    font-weight: 400;
  }
}

.bordered-author-info {
  margin: 1em 0;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  padding: 1em 0;
}

.ava-border {
  border-radius: 3px;
}
