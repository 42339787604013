.material-btn.btn, .material-btn.btn-large,
.material-btn.btn-flat {
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;

  &.text-transform-none {
    text-transform: none;
  }

  @include mobile {
    padding: 0 1rem;
  }

  .glyphicon {
    font-size: 12px;
  }
}

.material-btn.btn.btn-sm {
  height: 26px;
  line-height: 26px;
  font-size: 13px;
  padding:0px;
}

.material-btn.btn-danger.btn {
  background-color: #F44336;
  &:hover {
    background-color: lighten(#F44336, 5%);
  } 
}

.material-btn.btn.is-google, .material-btn.btn-large.is-google {
  background-color: $google-blue;
  &:hover {
    background-color: lighten($google-blue,5%);
  }
}

.material-btn.btn.is-grey, .material-btn.btn-large.is-grey {
  background-color: $google-grey;
  &:hover {
    background-color: lighten($google-grey,5%);
  }
}

.material-btn.btn.is-facebook, .material-btn.btn-large.is-facebook {
  background-color: $facebook-blue;
  &:hover {
    background-color: lighten($facebook-blue,5%);
  }
}
.material-btn.btn.like-color, .material-btn.btn-large.like-color {
  background-color: $like-color;
  &:hover {
    background-color: lighten($like-color,5%);
  }
}

.material-btn.btn.disabled, .material-btn.disabled.btn-large,
.material-btn.btn-floating.disabled,
.material-btn.btn-large.disabled,
.material-btn.btn-flat.disabled,
.material-btn.btn:disabled,
.material-btn.btn-large:disabled,
.material-btn.btn-floating:disabled,
.material-btn.btn-large:disabled,
.material-btn.btn-flat:disabled,
.material-btn.btn[disabled],
.material-btn[disabled].btn-large,
.material-btn.btn-floating[disabled],
.material-btn.btn-large[disabled],
.material-btn.btn-flat[disabled] {
  pointer-events: none;
  background-color: #DFDFDF !important;
  box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
}

.material-btn.btn.disabled:hover, .material-btn.disabled.btn-large:hover,
.material-btn.btn-floating.disabled:hover,
.material-btn.btn-large.disabled:hover,
.material-btn.btn-flat.disabled:hover,
.material-btn.btn:disabled:hover,
.material-btn.btn-large:disabled:hover,
.material-btn.btn-floating:disabled:hover,
.material-btn.btn-large:disabled:hover,
.material-btn.btn-flat:disabled:hover,
.material-btn.btn[disabled]:hover,
[disabled].material-btn.btn-large:hover,
.material-btn.btn-floating[disabled]:hover,
.material-btn.btn-large[disabled]:hover,
.material-btn.btn-flat[disabled]:hover {
  background-color: #DFDFDF !important;
  color: #9F9F9F !important;
}

.material-btn.btn, .material-btn.btn-large,
.material-btn.btn-floating,
.material-btn.btn-large,
.material-btn.btn-flat {
  font-size: 1rem;
  outline: 0;
}

.material-btn.btn i, .material-btn.btn-large i,
.material-btn.btn-floating i,
.material-btn.btn-large i,
.material-btn.btn-flat i {
  line-height: inherit;
}

.material-btn.btn:focus, .material-btn.btn-large:focus,
.material-btn.btn-floating:focus {
  background-color: #1d7d74;
}

.material-btn.btn, .material-btn.btn-large {
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: .5px;
  transition: .2s ease-out;
  cursor: pointer;
}

.material-btn.btn:hover, .material-btn.btn-large:hover {
  background-color: #2bbbad;
}

.material-btn.btn.is-secondary, .material-btn.btn-large.is-secondary {
  background-color: $main-alt;
  &:hover {
    background-color: lighten($main,5%);
  }
}

.material-btn.btn-floating {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #26a69a;
  border-radius: 50%;
  transition: .3s;
  cursor: pointer;
  vertical-align: middle;
}

.material-btn.btn-floating:hover {
  background-color: #26a69a;
}

.material-btn.btn-floating:before {
  border-radius: 0;
}

.material-btn.btn-floating.btn-large {
  width: 56px;
  height: 56px;
}

.material-btn.btn-floating.btn-large.halfway-fab {
  bottom: -28px;
}

.material-btn.btn-floating.btn-large i {
  line-height: 56px;
}

.material-btn.btn-floating.halfway-fab {
  position: absolute;
  right: 24px;
  bottom: -20px;
}

.material-btn.btn-floating.halfway-fab.left {
  right: auto;
  left: 24px;
}

.material-btn.btn-floating i {
  width: inherit;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 1.6rem;
  line-height: 40px;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  transition: .3s ease-out;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.7s ease-out;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0);
          transform: scale(0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.material-btn.btn, .material-btn.btn-large, .material-btn.btn-floating {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.material-btn.btn:hover, .material-btn.btn-large:hover, .material-btn.btn-floating:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}