// FONTS:
$font-regular: Arial;
$font-alter: 'Open Sans', sans-serif;

// COLORS:
$main-font: #373a3c;
$background: rgba(239, 238, 249, 0.6);
$main-alt: #828ed2;
.main-alt-cl {color: $main-alt !important;}
$main: #26a69a;
.main-cl {color: $main !important;}
$success: #5cb85c;
.success-cl {
  color: #5cb85c!important;
}
$link-cl: #6ca698;
.link-cl {
    color: $link-cl !important;
}
$like-color: rgba(255, 169, 28, 1);
.like-color {
    color: $like-color;
}
$google-blue: #4d90fe;
$google-blue-dark: #4376D8;
$google-blue-darken: #305196;
.google-blue {
  color: $google-blue;
}
$google-grey: #7785a3;
.google-grey {
  color: $google-blue;
}
$facebook-blue: #4267B2;
$facebook-blue-dark: #334B85;
$facebook-blue-darken: #26345e;
.facebook-blue {
  color: $facebook-blue;
}
.black {
    color: black;
}
$danger-cl: #d9534f;
.danger-cl {
  color: $danger-cl !important;
}


$white: #FFFFFF;
.white {
  color: $white !important;
}

.info-cl {
    color: #5bc0de;
}

$main-dark: rgb(103, 113, 170);

$lightgrey7:  #e7e7e7;
$shadow: rgba(0, 0, 0, 0.37);

/* MIXINS */
@mixin custom-scrolltumb {
  &::-webkit-scrollbar {
    width: 8px;
  }
   
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
   
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  }
}