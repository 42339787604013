.hidden-block {
    display: none;
}

.select-label-color {
    color:#9e9e9e;
    font-weight: 400;
}

.select-label-color {
    color:#9e9e9e;
    font-weight: 400;
}

.dentist-review-page {
    .addReviewBlock {
        border: 1px solid #26a69a!important;
        border-radius: 5px;

        .rating-caption {
            position: static;
            text-align: left;
            float: left;
            margin-left: 15px;
        }

        .smile-rating-module {
            .text-info {
                float: left;
                width: 40%;
                text-align: right;
                margin-right: 0px;
            }
            .smileys-wrap {
                float: left;
                margin-left: 15px;
            }
            .glyphicon-star.on {
                color: #ff6600;
                &::before {
                    background-color: transparent;
                    color: #ff6600;
                }
            }
        }

        @include mobile {
            .text-info {
                min-height: 50px;
            }
            .rating-caption {
                min-width: 50%;
            }
            .checkbox-title {
                display: block;
            }
            
            .input-field>label.active.label-textarea-bordered {
                transform: translateY(-28px) scale(0.7) !important;
                width: 130%;
                max-width: 130%;
            }
        }

        @media (max-width: 360px) {
            .smile-rating-module .text-info {
                width: 36%;
            }
        }
    }

    .faces-gallery-block {
      .photo-wrap {
        img {
            border-radius: 10px;
        }
      }
      .like-block {
        i {
            outline: none;
        }
      }
      .faces-gallery-wrap {
        display: block;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.22);
        &:hover {
            .close {
                display: block;
            }
        }
      }
      .close {
        position: absolute;
        display: none;
        top: calc(50% - 12px);
        font-size: 22px;
        right: 5px;
        opacity: 1;
        color: $danger-cl;
        opacity: 1;
        text-shadow: none;

      }
      .photo-caption {
        font-size: 14px;
        display: inline-block;
        margin-left: 10px;
      }
    }
}

