.l-main-header {
  background-color: $main;
  font-family: $font-alter;
  min-height: 50px;  
  border-color: transparent; 
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);

  @media(max-width: 992px) {
    .container {
      width: 100%
    }
  }
  .droogle-logo.ie-logo {
      display: none;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .droogle-logo {
        display: none;
     }
     .droogle-logo.ie-logo {
        display: block;
     }
  }
 

  .navbarwrapper {
    //padding-left: calc(8.33333333% + 210px);
    position: initial;
    .nav.navbar-nav.navbar-right {
      display: inline-flex;
      flex-direction: row-reverse;
      position: absolute;
      right: 15px;
    }
    @media(max-width: 1199px) {
      padding-left: 0;
    }
    @media(max-width: 992px) {
      padding: 0 15px;
    }
    @media(max-width: 778px) {
      .nav.navbar-nav.navbar-right {
        position: relative;
        display: block;
      }
    }
  }
  @include md-up {   
    max-height: 51px;
  }

  @include mobile {
    position: static;
  }

  @include mobile {
    .mobile-padding-0 {
      padding-left: 15px !important;
    }
  }

  .navbar-collapse, .navbar-default .navbar-form {
    max-height: none !important;
  }

  .navbar-right .main-link a, .navbar-left .main-link a  {
    color: white!important;
    font-weight: bold;
    outline: none;
    transition: all 0.3s ease;
    &:hover {
      color: rgba($white,0.65)!important;
    }
    &:active {
      color: rgba($white,0.65)!important;
    }
  }

  .navbar-brand {
    padding: 9px 15px;
    .droogle-logo {
      height: 32px;
      margin-top:-2px;
      margin-left:20px;
    }
  }

.droogle-logo {
      height: 32px;
      margin-top:-2px;
    }
  .right-nav {
    float: right;
    &.dropdown {
      &.open {
        .dropdown-toggle {
          background-color: rgba($white,0.1) !important;
          color: $white;
        }
      }
      .dropdown-toggle {
        height: 50px;
        display: flex;
        color: $white;
        &:hover {
          background-color: rgba($white, 0.1) !important;
        }
      }
    }
  }

  .navbar-toggle {
    border-color: $white;
    .icon-bar {
      background-color: $white;
    }
    &:hover, &:focus {
      background-color: rgba($white,0.1);
    }
  }

  /* NEW EDITING FOR HEADER */
  @media (min-width: 1200px) {
    .navbarwrapper {
      .navbar-header {
        width: 275px;
        .navbar-brand {
          padding-left: 0px;
          margin: 0;
          width: 100%;
          text-align: left;
        }
      }

      .navbar-collapse {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden !important;
        white-space: nowrap;
        padding-left: 15px;
        margin-left: 25%;
        > ul.navbar-left {
          width: 100%;
          text-align: left;
          > li {
            display: inline-block;
            float: none !important;
          }
        }
      }

    }
  }
}

.mobile-search-form .elastic-search {
  @include mobile {
    margin-right: 0;
    .form-control {
      width: 100% !important;
    }
  }
}

.full-width-form .elastic-search {
  margin-right: 0;
  width: 100% !important;
  .form-control {
    width: 100% !important;
  }
}

.elastic-search {
    box-shadow: none;
    border: none;
    position: relative;
    padding: 0;
    margin-right: 15px;
    margin-left: 15px;

    &.full-width {
      .form-control {
        width: 100% !important;
      }
    }

    .form-group {
      display: flex;
      .form-control {
        width: 180px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .input-group-btn {
        width: 40px;
        margin: 0px !important;
        > .btn {
          width: 40px;
          height: 34px;
          background: #2bbbad;
          border: 1px solid #2bbbad;
          &:hover {
            background: #4cd8ca;
            border: 1px solid #4cd8ca;
          }
        }
        span {
          margin: 0px !important;
        }
      }
    }

    @include mobile {
      margin-left: 0px;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .form-group .form-control {
        width: 180px;
      }
    }

    .elastic-search-result {
      position: absolute;
      list-style-type: none;
      background: $white;
      width: calc(100% - 30px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 1px solid rgba(0,0,0,0.15);
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0,0,0,0.175);
      li > a {
        display: block;
        padding: 3px 20px;
        color: #333;
        &:hover {
          color: #262626;
          text-decoration: none;
          background-color: #f5f5f5;
        }
      }
      @include mobile {
        max-height: 220px;
        overflow: auto;
        z-index: 100;
      }
    }
  }