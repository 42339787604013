/* STYLES FOR ALL INPUTS */

.main-input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  box-shadow: none;
  outline: none;

  &:focus {
    box-shadow: none;
  }

  &.no-border-input {
    border: none;
    box-shadow: none;
  }

  &.parsley-error {
    border: 1px solid #d50000;
  }

  &.parsley-success {
    border: 1px solid #37a000;
  }
}

.parsley-errors-list {
  margin-top: 5px;
  text-align: left;
  color: #d50000;
  list-style-type: none;
  text-transform: none;
}

input[type="file"].beforeAfterPhoto + .parsley-errors-list {
  margin-left: -30px;
  margin-top: 0px;
}



/* CHECKBOX CUSTOM */

/* .squaredFour */
.checkbox-custom {
  position: relative;
  label {
    cursor: pointer;
    padding-left: 30px;
    font-weight: 400;
    &:after {
      content: '';
      width: 12px;
      height: 7px;
      position: absolute;
      top: 5px;
      left: 4px;
      border: 3px solid $main;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      background: $white;
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      border-radius: 4px;
      box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    }
    &:hover::after {
      opacity: 0.5;
    }
  }
  input[type=checkbox] {
    width: 20px;
    visibility: hidden;
    position: absolute;
    left: 0;
    &:checked + label:after {
      opacity: 1;
    }
  }
}

.schedule_error {
  display: block;
  color: #d50000;
  text-align: left;
  margin-top: 5px;
}