.carousel-fade {
	.carousel-inner {
	    .item {
	        transition-property: opacity;
	    }
	    
	    .item,
	    .active.left,
	    .active.right {
	        opacity: 0;
	    }

	    .active,
	    .next.left,
	    .prev.right {
	        opacity: 1;
	    }

	    .next,
	    .prev,
	    .active.left,
	    .active.right {
	        left: 0;
	        transform: translate3d(0, 0, 0);
	    }
	}

	.carousel-control {
	    z-index: 2;
	}
}