.faq-page {
  .faq-categories {
    background: $main;
    color: $white;
    position: absolute;
    left: 15px;
    top: 0;
    width: 200px;
    border-radius: 0.25rem;
    &.fixed {
      position: fixed;
      top: 110px;
      left: calc( (100% - 1170px) / 2 + 15px);
      @media (min-width: 992px) and (max-width: 1199px) {
        left: calc( (100% - 970px) / 2 + 15px);
      }
    }
  }

  .special-red {
    color: rgb(128, 0, 0);
  }

  .faq-group {
    > li {
      background: #ffffff;
      margin-bottom: 6px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
      -webkit-transition: box-shadow 0.2s;
      -moz-transition: box-shadow 0.2s;
      transition: box-shadow 0.2s;
      list-style-type: none;
    }
    .faq-title {
      box-shadow: none;
      background: transparent;
      h2 {
        text-transform: uppercase;
        font-size: 1rem;
      }
    }
    .faq-trigger {
      display: block;
      font-size: 1.5rem;
      font-weight: 300;
      margin: 0;
      padding: 24px;
      cursor: pointer;
      -webkit-user-select: none;
      &::before {
        content: "\e259";
        float: right;
        margin-left: 24px;
        font-family: 'Glyphicons Halflings';
      }
      @include mobile {
        font-size: 1.2rem;
        padding: 12px;
      }
    }
    .faq-content {
      display: none;
      padding: 0 24px 30px;
      line-height: 1.6;
      color: #6c7d8e;
      ul,ol {
        padding-left: 20px;
      }
    }
    .content-visible {
      .faq-trigger::before {
        content: "\e260";
      }
    }
  }

}
