// RESET/BASE CSS:
@import "core/_variables";
@import "core/_media-queries";
@import "core/_helper-classes";
@import "core/_normalize";
@import "core/_base";
@import "core/_spacing";
@import "materialize_modules/_materialize-inputs";
@import "materialize_modules/_materialize-btns";

// REUSABLE PART - LAYOUTS AND MODULES:
@import "modules/_small-modules";
@import "modules/_button";
@import "modules/_inputs";
@import "modules/_item-block";
@import "modules/_modals";
@import "modules/tag_cloud";
@import "modules/_chart";
@import "modules/_carousel";


@import "layouts/_header";
@import "layouts/_footer";


// PAGES BASED ON BODY CLASS:
@import "pages/_dentist-review";
@import "pages/_faq-page";
@import "pages/_dentist-management";
@import "pages/_temp-review-page";
@import "pages/_dentist-location";
