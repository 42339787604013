.dentist-management {
	#address-second-error, #address-third-error {
        display: none;
    }
	#second-address-form, #third-address-form {
		display: none;
	}
	.materialize-textarea.material-input[readonly], input.material-input[readonly] {
		color: #7d7d7d !important;
	}

	.addImageBlock {
	  overflow: hidden;
	  min-height: 75px;
	  > .overlay {
	    position: absolute;
	    z-index: 510;
	    height: 100%;
	    width: 100%;
	    background: rgba(0, 0, 0, 0.45); 
	    opacity: 0;
	    top: 0;
	    transition: all 0.3s ease;
	  }

	  > .form-group {
	    position: absolute;
	    bottom: 15px;
	    width: 100%;
	    z-index: 511;
	    transform: translateY(300%);
	    transition: all 0.5s ease;
	  }

	  &:hover {
	    > .overlay {
	      opacity: 1;
	    }
	    > .form-group {
	      transform: translateY(0);
	    }
	  }
	}
	.jsHoverToggle:not(.addImageBlock) {
		> .overlay {
			display: none;
		}

		> .form-group {
			display: none;
		}
	}
}

/* IFRAME BLOCK */
.cases-fixed-photo-wrap {
	width: 100%;
	height: 250px !important;
	img {
		width: auto;
		max-width: 100%;
		max-height: 250px;
		height: auto;
		text-align: center;
	}
}

.note-toolbar.panel-heading {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
}

.title-summernote {
	color: #9e9e9e;
    text-align: start;
}

textarea.materialize-textarea.height-10 {
	min-height: 10em;
}

.note-toolbar.panel-heading>.btn-group {
	display: flex !important;
    flex-direction: row;
    justify-content: space-around
}

.note-toolbar.panel-heading>.btn-group>div {
	display: flex;
}