/* STYLES FOR ALL BUTTONS */

.btn {
  outline: none !important;
}

.btn-primary {
  background-color: $main;
  border-color: $main;
}

.btn-primary:hover,
.btn-primary:disabled:hover,
.btn-primary.disabled:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background: $main-dark;
  border-color: $main-dark;
}


.btn-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success:hover,
.btn-success:disabled:hover,
.btn-success.disabled:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active {
  background-color: #449d44;
  border-color: #419641;
}

.main-button:hover,
.main-button:disabled:hover,
.main-button.disabled:hover,
.main-button:focus,
.main-button:active,
.main-button.active {
  color: $white;
}

/* MAIN BUTTON */
.main-button {
/*  font-size: 14px; */
  -webkit-box-shadow: 0 4px 2px -2px $shadow;
  -moz-box-shadow: 0 4px 2px -2px $shadow;
  box-shadow: 0 4px 2px -2px $shadow;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  line-height: 1.25;
  color: $white;

  &:hover {
    color: white;
  }

  &.is-transparent {
    box-shadow: none;
    border-color: transparent;
    background: transparent;
  }

  &.is-large-btn {
    width: 350px;
    max-width: 100%;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.is-google-button {
    background: $google-blue;
    border: 1px solid $google-blue;
    &:hover {
      background: $google-blue-dark;
      border: 1px solid $google-blue-dark;
    }
    &:active {
      background: $google-blue-darken;
      border: 1px solid $google-blue-darken;
    }
  }

  &.is-facebook-button {
    background: $facebook-blue;
    border: 1px solid $facebook-blue;
    &:hover {
      background: $facebook-blue-dark;
      border: 1px solid $facebook-blue-dark;
    }
    &:active {
      background: $facebook-blue-darken;
      border: 1px solid $facebook-blue-darken;
    }
  }

  &.is-material-button {
    background: #26a69a;
    border: 1px solid #26a69a;
    &:hover {
      background: #4cd8ca;
      border: 1px solid #4cd8ca;
    }
    &:active {
      background: #4cd8ca;
      border: 1px solid #4cd8ca;
    }
  }

  &.emotion {
    background-color: #fff9fc;
    border: 1px solid #BCBCDE;
    color: #000;
    box-shadow: none;
    &:hover {
      background-color: #fff9fc;
      border: 1px solid #BCBCDE;
    }
  }

  &.emotion-positive {
    background-color: rgba(38, 166, 154, 0.93);
    border: 1px solid $main;
    color: #FFFFFF;
    box-shadow: none;
    &:hover {
      background-color: rgba(38, 166, 154, 0.93);
      border: 1px solid $main;
    }
  }


    &.emotion-negative {
      background-color: #ef5350;
      border: 1px solid #ef5350;
      color: white;
      box-shadow: none;
    &:hover {
      background-color: #ef5350;
      border: 1px solid #ef5350;
    }
  }

  &.btn-default {
    color: #333;
    &:hover,&:focus,&:active {
      color: #333;
    }
  }

  .icon-addon {
    padding: 8px;
    padding-top: 7px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    background: $white;
    float: left;
    width: 35px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      max-width: 14px;
    }
  }

  .addon-main-text {
    width: calc(100% - 67px);
    margin-left: 36px;
    display: block;
    padding: 0.5rem 1rem;
  }

  > .glyphicon {
    font-size: 12px;
  }

}

.search-shadow {
  box-shadow: 0px 2px 3px rgba(0,0,0,0.5);
}

.compareBtn {
  border: 1px solid #ACACAC;
  background: #E5E5E5;
  height: 70%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.compareBtn-mobile {
  border: 1px solid #ACACAC;
  background: #E5E5E5;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}