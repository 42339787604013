/* Text Inputs + Textarea
   ========================================================================== */
/* Style Placeholders */
.material-input::-webkit-input-placeholder {
  color: #d1d1d1;
}

.material-input:-moz-placeholder {
  /* Firefox 18- */
  color: #d1d1d1;
}

.material-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #d1d1d1;
}

.material-input:-ms-input-placeholder {
  color: #d1d1d1;
}

.material-input.parsley-error, .material-input.appointment-error {
  border-bottom: 1px solid #F44336 !important;
  box-shadow: 0 1px 0 0 #F44336 !important;
}

.material-input.parsley-success {
  border-bottom: 1px solid #4CAF50 !important;
  box-shadow: 0 1px 0 0 #4CAF50 !important;
}

.material-input.materialize-textarea.double-height {
  height: 5rem;
}

.material-input.materialize-textarea.bordered-textarea {
  border: 1px solid #9e9e9e !important;
  padding: 0.8rem !important;
  width: calc(100% - 1.6rem);
}

.input-field > label.label-textarea-bordered {
  padding-left: 0.5rem;
}

.input-field > label.active.label-textarea-bordered {
  transform: translateY(-18px) scale(0.8) !important;
  padding-left: 0;
}


/* Text inputs */
input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  &.material-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
  }
}

input:not([type]):disabled, input:not([type])[readonly="readonly"],
input[type=text]:not(.browser-default):disabled,
input[type=text]:not(.browser-default)[readonly="readonly"],
input[type=password]:not(.browser-default):disabled,
input[type=password]:not(.browser-default)[readonly="readonly"],
input[type=email]:not(.browser-default):disabled,
input[type=email]:not(.browser-default)[readonly="readonly"],
input[type=url]:not(.browser-default):disabled,
input[type=url]:not(.browser-default)[readonly="readonly"],
input[type=time]:not(.browser-default):disabled,
input[type=time]:not(.browser-default)[readonly="readonly"],
input[type=date]:not(.browser-default):disabled,
input[type=date]:not(.browser-default)[readonly="readonly"],
input[type=datetime]:not(.browser-default):disabled,
input[type=datetime]:not(.browser-default)[readonly="readonly"],
input[type=datetime-local]:not(.browser-default):disabled,
input[type=datetime-local]:not(.browser-default)[readonly="readonly"],
input[type=tel]:not(.browser-default):disabled,
input[type=tel]:not(.browser-default)[readonly="readonly"],
input[type=number]:not(.browser-default):disabled,
input[type=number]:not(.browser-default)[readonly="readonly"],
input[type=search]:not(.browser-default):disabled,
input[type=search]:not(.browser-default)[readonly="readonly"],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly="readonly"] {
  &.material-input {
      color: rgba(0, 0, 0, 0.26);
      border-bottom: 1px dotted rgba(0, 0, 0, 0.26);
  }
}

input.material-input:not([type]):disabled + label,
input.material-input:not([type])[readonly="readonly"] + label,
input[type=text].material-input:not(.browser-default):disabled + label,
input[type=text].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=password].material-input:not(.browser-default):disabled + label,
input[type=password].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=email].material-input:not(.browser-default):disabled + label,
input[type=email].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=url].material-input:not(.browser-default):disabled + label,
input[type=url].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=time].material-input:not(.browser-default):disabled + label,
input[type=time].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=date].material-input.material-input:not(.browser-default):disabled + label,
input[type=date].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=datetime].material-input:not(.browser-default):disabled + label,
input[type=datetime].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=datetime-local].material-input:not(.browser-default):disabled + label,
input[type=datetime-local].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=tel].material-input:not(.browser-default):disabled + label,
input[type=tel].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=number].material-input:not(.browser-default):disabled + label,
input[type=number].material-input:not(.browser-default)[readonly="readonly"] + label,
input[type=search].material-input:not(.browser-default):disabled + label,
input[type=search].material-input:not(.browser-default)[readonly="readonly"] + label,
textarea.materialize-textarea:disabled + label,
textarea.materialize-textarea[readonly="readonly"] + label {
  color: rgba(0, 0, 0, 0.26);
}

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  &.material-input {
    border-bottom: 1px solid #26a69a;
    box-shadow: 0 1px 0 0 #26a69a;
  }
}

input.material-input:not([type]):focus:not([readonly]) + label,
input[type=text].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=password].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=email].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=url].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=time].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=date].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime-local].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=tel].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=number].material-input:not(.browser-default):focus:not([readonly]) + label,
input[type=search].material-input:not(.browser-default):focus:not([readonly]) + label,
textarea.material-input.materialize-textarea:focus:not([readonly]) + label {
  color: #26a69a;
}

input:not([type]).valid, input:not([type]):focus.valid,
input[type=text]:not(.browser-default).valid,
input[type=text]:not(.browser-default):focus.valid,
input[type=password]:not(.browser-default).valid,
input[type=password]:not(.browser-default):focus.valid,
input[type=email]:not(.browser-default).valid,
input[type=email]:not(.browser-default):focus.valid,
input[type=url]:not(.browser-default).valid,
input[type=url]:not(.browser-default):focus.valid,
input[type=time]:not(.browser-default).valid,
input[type=time]:not(.browser-default):focus.valid,
input[type=date]:not(.browser-default).valid,
input[type=date]:not(.browser-default):focus.valid,
input[type=datetime]:not(.browser-default).valid,
input[type=datetime]:not(.browser-default):focus.valid,
input[type=datetime-local]:not(.browser-default).valid,
input[type=datetime-local]:not(.browser-default):focus.valid,
input[type=tel]:not(.browser-default).valid,
input[type=tel]:not(.browser-default):focus.valid,
input[type=number]:not(.browser-default).valid,
input[type=number]:not(.browser-default):focus.valid,
input[type=search]:not(.browser-default).valid,
input[type=search]:not(.browser-default):focus.valid,
textarea.materialize-textarea.valid,
textarea.materialize-textarea:focus.valid {
  &.material-input {
      border-bottom: 1px solid #4CAF50;
      box-shadow: 0 1px 0 0 #4CAF50;
  }
}

input.material-input:not([type]).valid + label:after,
input.material-input:not([type]):focus.valid + label:after,
input[type=text].material-input:not(.browser-default).valid + label:after,
input[type=text].material-input:not(.browser-default):focus.valid + label:after,
input[type=password].material-input:not(.browser-default).valid + label:after,
input[type=password].material-input:not(.browser-default):focus.valid + label:after,
input[type=email].material-input:not(.browser-default).valid + label:after,
input[type=email].material-input:not(.browser-default):focus.valid + label:after,
input[type=url].material-input:not(.browser-default).valid + label:after,
input[type=url].material-input:not(.browser-default):focus.valid + label:after,
input[type=time].material-input:not(.browser-default).valid + label:after,
input[type=time].material-input:not(.browser-default):focus.valid + label:after,
input[type=date].material-input:not(.browser-default).valid + label:after,
input[type=date].material-input:not(.browser-default):focus.valid + label:after,
input[type=datetime].material-input:not(.browser-default).valid + label:after,
input[type=datetime].material-input:not(.browser-default):focus.valid + label:after,
input[type=datetime-local].material-input:not(.browser-default).valid + label:after,
input[type=datetime-local].material-input:not(.browser-default):focus.valid + label:after,
input[type=tel].material-input:not(.browser-default).valid + label:after,
input[type=tel].material-input:not(.browser-default):focus.valid + label:after,
input[type=number].material-input:not(.browser-default).valid + label:after,
input[type=number].material-input:not(.browser-default):focus.valid + label:after,
input[type=search].material-input:not(.browser-default).valid + label:after,
input[type=search].material-input:not(.browser-default):focus.valid + label:after,
textarea.material-input.materialize-textarea.valid + label:after,
textarea.material-input.materialize-textarea:focus.valid + label:after {
    content: attr(data-success);
    color: #4CAF50;
    opacity: 1;
}

input:not([type]).invalid, input:not([type]):focus.invalid,
input[type=text]:not(.browser-default).invalid,
input[type=text]:not(.browser-default):focus.invalid,
input[type=password]:not(.browser-default).invalid,
input[type=password]:not(.browser-default):focus.invalid,
input[type=email]:not(.browser-default).invalid,
input[type=email]:not(.browser-default):focus.invalid,
input[type=url]:not(.browser-default).invalid,
input[type=url]:not(.browser-default):focus.invalid,
input[type=time]:not(.browser-default).invalid,
input[type=time]:not(.browser-default):focus.invalid,
input[type=date]:not(.browser-default).invalid,
input[type=date]:not(.browser-default):focus.invalid,
input[type=datetime]:not(.browser-default).invalid,
input[type=datetime]:not(.browser-default):focus.invalid,
input[type=datetime-local]:not(.browser-default).invalid,
input[type=datetime-local]:not(.browser-default):focus.invalid,
input[type=tel]:not(.browser-default).invalid,
input[type=tel]:not(.browser-default):focus.invalid,
input[type=number]:not(.browser-default).invalid,
input[type=number]:not(.browser-default):focus.invalid,
input[type=search]:not(.browser-default).invalid,
input[type=search]:not(.browser-default):focus.invalid,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea:focus.invalid {
  &.material-input {
      border-bottom: 1px solid #F44336;
      box-shadow: 0 1px 0 0 #F44336;
  }
}

input:not([type]).invalid + label:after,
input:not([type]):focus.invalid + label:after,
input[type=text]:not(.browser-default).invalid + label:after,
input[type=text]:not(.browser-default):focus.invalid + label:after,
input[type=password]:not(.browser-default).invalid + label:after,
input[type=password]:not(.browser-default):focus.invalid + label:after,
input[type=email]:not(.browser-default).invalid + label:after,
input[type=email]:not(.browser-default):focus.invalid + label:after,
input[type=url]:not(.browser-default).invalid + label:after,
input[type=url]:not(.browser-default):focus.invalid + label:after,
input[type=time]:not(.browser-default).invalid + label:after,
input[type=time]:not(.browser-default):focus.invalid + label:after,
input[type=date]:not(.browser-default).invalid + label:after,
input[type=date]:not(.browser-default):focus.invalid + label:after,
input[type=datetime]:not(.browser-default).invalid + label:after,
input[type=datetime]:not(.browser-default):focus.invalid + label:after,
input[type=datetime-local]:not(.browser-default).invalid + label:after,
input[type=datetime-local]:not(.browser-default):focus.invalid + label:after,
input[type=tel]:not(.browser-default).invalid + label:after,
input[type=tel]:not(.browser-default):focus.invalid + label:after,
input[type=number]:not(.browser-default).invalid + label:after,
input[type=number]:not(.browser-default):focus.invalid + label:after,
input[type=search]:not(.browser-default).invalid + label:after,
input[type=search]:not(.browser-default):focus.invalid + label:after,
textarea.materialize-textarea.invalid + label:after,
textarea.materialize-textarea:focus.invalid + label:after {
  &.material-input {
    content: attr(data-error);
    color: #F44336;
    opacity: 1;
  }
}

input:not([type]).validate + label,
input[type=text]:not(.browser-default).validate + label,
input[type=password]:not(.browser-default).validate + label,
input[type=email]:not(.browser-default).validate + label,
input[type=url]:not(.browser-default).validate + label,
input[type=time]:not(.browser-default).validate + label,
input[type=date]:not(.browser-default).validate + label,
input[type=datetime]:not(.browser-default).validate + label,
input[type=datetime-local]:not(.browser-default).validate + label,
input[type=tel]:not(.browser-default).validate + label,
input[type=number]:not(.browser-default).validate + label,
input[type=search]:not(.browser-default).validate + label,
textarea.materialize-textarea.validate + label {
  &.material-input {
    width: 100%;
    pointer-events: none;
  }
}

input:not([type]) + label:after,
input[type=text]:not(.browser-default) + label:after,
input[type=password]:not(.browser-default) + label:after,
input[type=email]:not(.browser-default) + label:after,
input[type=url]:not(.browser-default) + label:after,
input[type=time]:not(.browser-default) + label:after,
input[type=date]:not(.browser-default) + label:after,
input[type=datetime]:not(.browser-default) + label:after,
input[type=datetime-local]:not(.browser-default) + label:after,
input[type=tel]:not(.browser-default) + label:after,
input[type=number]:not(.browser-default) + label:after,
input[type=search]:not(.browser-default) + label:after,
textarea.materialize-textarea + label:after {
  &.material-input {
    display: block;
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    opacity: 0;
    transition: .2s opacity ease-out, .2s color ease-out;
  }
}

.input-field {
  position: relative;
  margin-top: 1rem;
}

.input-field.inline {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.input-field.inline input,
.input-field.inline .select-dropdown {
  margin-bottom: 1rem;
}

.input-field.col label {
  left: 0.75rem;
}

.input-field.col .prefix ~ label,
.input-field.col .prefix ~ .validate ~ label {
  width: calc(100% - 3rem - 1.5rem);
}

.input-field label {
  color: #9e9e9e;
  position: absolute;
  left: 0;
  font-weight: 400;
  left: inherit !important;


  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  font-size: 1rem;
  cursor: text;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  text-align: initial;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  pointer-events: none;
}

.input-field label:not(.appointment-error):not(.label-icon).active {
  -webkit-transform: translateY(-14px) scale(0.8);
  transform: translateY(-14px) scale(0.8);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.input-field .prefix {
  position: absolute;
  width: 3rem;
  font-size: 2rem;
  transition: color .2s;
}

.input-field .prefix.active {
  color: #26a69a;
}

.input-field .prefix ~ input,
.input-field .prefix ~ textarea,
.input-field .prefix ~ label,
.input-field .prefix ~ .validate ~ label,
.input-field .prefix ~ .autocomplete-content {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.input-field .prefix ~ label {
  margin-left: 3rem;
}

@media only screen and (max-width: 992px) {
  .input-field .prefix ~ input {
    width: 86%;
    width: calc(100% - 3rem);
  }
}

@media only screen and (max-width: 600px) {
  .input-field .prefix ~ input {
    width: 80%;
    width: calc(100% - 3rem);
  }
}

/* Search Field */
.input-field input[type=search] {
  display: block;
  line-height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);
}

.input-field input[type=search]:focus {
  background-color: #fff;
  border: 0;
  box-shadow: none;
  color: #444;
}

.input-field input[type=search]:focus + label i,
.input-field input[type=search]:focus ~ .mdi-navigation-close,
.input-field input[type=search]:focus ~ .material-icons {
  color: #444;
}

.input-field input[type=search] + label {
  left: 1rem;
}

.input-field input[type=search] ~ .mdi-navigation-close,
.input-field input[type=search] ~ .material-icons {
  position: absolute;
  top: 0;
  right: 1rem;
  color: transparent;
  cursor: pointer;
  font-size: 2rem;
  transition: .3s color;
}

/* Textarea */
textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
}

textarea.materialize-textarea {
  overflow-y: hidden;
  /* prevents scroll bar flash */
  padding: .8rem 0 .8rem 0 !important;
  /* prevents text jump on Enter keypress */
  resize: none;
  min-height: 3rem;
}

.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem;
  /* prevents text jump on Enter keypress */
  position: absolute;
  top: 0;
}

/* Autocomplete */
.autocomplete-content {
  margin-top: -20px;
  display: block;
  opacity: 1;
  position: static;
}

.autocomplete-content li .highlight {
  color: #444;
}

.autocomplete-content li img {
  height: 40px;
  width: 40px;
  margin: 5px 15px;
}

/* Radio Buttons
   ========================================================================== */
[type="radio"]:not(:checked),
[type="radio"]:checked {
  &.material-input {
      position: absolute;
      left: -9999px;
      opacity: 0;
    }
}

[type="radio"]:not(:checked).material-input + label,
[type="radio"]:checked.material-input + label {
  font-weight: 400;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;
  /* webkit (konqueror) browsers */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

[type="radio"].material-input + label:before,
[type="radio"].material-input + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease;
}

/* Unchecked styles */
[type="radio"].material-input:not(:checked) + label:before,
[type="radio"].material-input:not(:checked) + label:after,
[type="radio"].material-input:checked + label:before,
[type="radio"].material-input:checked + label:after,
[type="radio"].with-gap.material-input:checked + label:before,
[type="radio"].with-gap.material-input:checked + label:after {
  border-radius: 50%;
}

[type="radio"].material-input:not(:checked) + label:before,
[type="radio"].material-input:not(:checked) + label:after {
  border: 2px solid #5a5a5a;
}

[type="radio"].material-input:not(:checked) + label:after {
  -webkit-transform: scale(0);
          transform: scale(0);
}

/* Checked styles */
[type="radio"].material-input:checked + label:before {
  border: 2px solid transparent;
}

[type="radio"].material-input:checked + label:after,
[type="radio"].material-input.with-gap:checked + label:before,
[type="radio"].material-input.with-gap:checked + label:after {
  border: 2px solid #26a69a;
}

[type="radio"].material-input:checked + label:after,
[type="radio"].material-input.with-gap:checked + label:after {
  background-color: #26a69a;
}

[type="radio"].material-input:checked + label:after {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

/* Radio With gap */
[type="radio"].material-input.with-gap:checked + label:after {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

/* Focused styles */
[type="radio"].material-input.tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Disabled Radio With gap */
[type="radio"].material-input.with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, 0.26);
}

[type="radio"].material-input.with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

/* Disabled style */
[type="radio"].material-input:disabled:not(:checked) + label:before,
[type="radio"].material-input:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26);
}

[type="radio"].material-input:disabled + label {
  color: rgba(0, 0, 0, 0.26);
}

[type="radio"].material-input:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}

[type="radio"].material-input:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #BDBDBD;
}

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */

/* Remove default checkbox */
[type="checkbox"].material-input:not(:checked),
[type="checkbox"].material-input:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type="checkbox"].material-input + label {
  text-align: left;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

[type="checkbox"].material-input + label:before,
[type="checkbox"].material-input:not(.filled-in) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 2px;
  transition: .2s;
}

[type="checkbox"].material-input:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

[type="checkbox"].material-input:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

[type="checkbox"].material-input.tabbed:focus + label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].material-input:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"].material-input:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26);
}

/* Indeterminate checkbox */
[type="checkbox"].material-input:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"].material-input:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

[type="checkbox"].material-input.filled-in + label:after {
  border-radius: 2px;
}

[type="checkbox"].material-input.filled-in + label:before,
[type="checkbox"].material-input.filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1;
}

[type="checkbox"].material-input.filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}

[type="checkbox"].material-input.filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0px;
  z-index: 0;
}

[type="checkbox"].material-input.filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].material-input.filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}

[type="checkbox"].material-input.filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].material-input.filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a;
}

[type="checkbox"].material-input.filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type="checkbox"].material-input.filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #BDBDBD;
}

[type="checkbox"].material-input.filled-in:disabled:checked + label:before {
  background-color: transparent;
}

[type="checkbox"].material-input.filled-in:disabled:checked + label:after {
  background-color: #BDBDBD;
  border-color: #BDBDBD;
}

/* File Input
   ========================================================================== */
.file-field {
  position: relative;
}

.file-field .file-path-wrapper {
  overflow: hidden;
  padding-left: 10px;
}

.file-field input.file-path {
  width: 100%;
  height: 2.5rem !important;
}

.file-field .btn, .file-field .btn-large {
  float: left;
  height: 3rem;
  line-height: 3rem;
}

.file-field span {
  cursor: pointer;
}

.file-field input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}