.chart .title {
    padding:15px;
    border: 1px solid black;
    margin-bottom: 10px;
}
.chart ul {
        width:100%;
        margin-bottom:20px;
        overflow:hidden;
}
.chart li {
        text-align:left;
        line-height: 2em;
        float:left;
        display:inline;
}
.chart #double li  {
    width:50%;
    font-size: 15px;
}
.chart #double span {
    display: inline-block;
    width:15px;
    height:15px;
    border: 2px solid black;
    margin-right: 5px;
}

.chart-wrapper {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow:hidden;
  @include xl {
    padding-bottom: 75%;
  }
}

#chart_div {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
}